<!--
 * @Author: CL
 * @Date: 2021-06-25 16:25:10
 * @LastEditTime: 2021-08-03 23:51:45
 * @Description: 修改项目信息
-->

<template>
  <div class="editcodemodel-contain">
    <div class="main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="项目标题" prop="title">
          <el-input clearable class="cl-dialog" v-model="ruleForm.title" placeholder="必填，请输入项目标题"></el-input>
        </el-form-item>
        <el-form-item label="项目地址" prop="address">
          <el-input clearable class="cl-dialog" v-model="ruleForm.address" placeholder="必填,请输入项目地址"></el-input>
        </el-form-item>
        <el-form-item label="是否是游戏" prop="isGame">
          <el-radio-group v-model="ruleForm.isGame">
            <el-radio :label="true" value="true">是游戏</el-radio>
            <el-radio :label="false" value="false">不是游戏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="项目创建时间" prop="createTime">
           <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="ruleForm.createTime" style="width: 100%;"></el-date-picker>
        </el-form-item>
        <el-form-item label="项目配图" prop="picture">
          <el-upload
            :action="uploadURL"
            :headers="headers"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :on-exceed="handleExceed"
            :limit="1"
            :file-list="fileList"
            :with-credentials="true"
            ref="upload"
            name="img"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </el-form>
    </div>
    <div class="footer cl-dialog-footer">
      <el-button class="my-button" @click="closeModel">取消</el-button>
      <el-button class="my-button" type="primary" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { editProject } from '@/api/project';

export default {
  data(){
    return {
      ruleForm: {
        title: '',         //项目标题
        address: '',       //请输入项目地址
        isGame: '',        //是否是游戏
        picture: '',       //缩略图地址
        views: 0,          //观看次数
        likes: 0,          //点赞次数
        createTime: '',    //项目创建时间
      },

      rules: {
        title: [
          { required: true, message: '项目标题不能为空', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '项目地址不能为空', trigger: 'blur' },
        ],
        isGame: [
          { required: true, message: '请选择是否是游戏', trigger: 'change' },
        ],
        createTime: [
          { required: true, message: '创建时间不能为空', trigger: 'change' },
        ],
        picture: [
          { required: true, message: '项目配图不能为空', trigger: 'blur' },
        ],
      },

      dialogImageUrl: '',
      dialogVisible: false,
      fileList: []
    }
  },

  methods: {
    /**
     * 取消提交
     */
    closeModel(){
      this.close();
    },

    /**
     * 保存
     */
    save(){
      this.$refs['ruleForm'].validate(async (valid) => {
        if (!valid) {
          return;
        }
        try{
          const res = await editProject(this.ruleForm.id, this.ruleForm);
          if(res.code == 200){
            this.$showMessage({
              type: 'success',
              message: '修改成功'
            })
            this.close('refresh');
          }
        }catch(err){
          console.log(err);
        }
      });
    },

    close(data){
      this.$emit('closeEditCodeModel', false, data);
      this.$refs['ruleForm'].resetFields();
      this.$refs['upload'].clearFiles();
    },

    /**
     * 移除图片
     */
    handleRemove() {
      this.$set(this.ruleForm, 'picture', '');
      this.fileList = [];
    },

    /**
     * 上传成功的回调
     */
    handleSuccess(res){
      console.log(res);
      if(res.code == 200){
        this.$set(this.ruleForm, 'picture', res.data);
      }
    },

    /**
     * 限制上传的个数
     */
    handleExceed(){
      this.$showMessage({
        type: 'warning',
        message: '只能上传一张图片哦'
      })
    },

    /**
     * 预览图片
     */
    handlePictureCardPreview(file){
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    firstTo(data){
      this.ruleForm = this.$utils.deepClone(data);
      this.ruleForm = {
        ...this.ruleForm,
        isGame: this.ruleForm.isGame == '是'?  true : false
      }
      if(this.ruleForm.picture){
        this.$set(this.fileList, 0, {url: this.baseURL + this.ruleForm.picture});
      }else{
        this.fileList = [];
      }
    }
  },

  computed: {
    ...mapState(['uploadURL', 'headers', 'baseURL']),
  },

  props: {
    editInfo: {
      type: Object,
      required: true
    }
  },

  watch: {
    editInfo: {
      handler(val){
        this.firstTo(val);
      }
    }
  },

  created(){
    this.firstTo(this.editInfo);
  }
}
</script>

<style lang="less" scoped>

</style>