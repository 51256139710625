<!--
 * @Author: CL
 * @Date: 2021-06-23 13:08:42
 * @LastEditTime: 2021-07-30 19:23:46
 * @Description: 代码效果管理后台
-->

<template>
  <div class="codeback-contain cl-wrap">
    <div class="cl-table-header">
      <el-row style="width: 100%" :gutter="20">
        <el-col :span="6">
          <el-input class="cl-input" v-model="searchForm.title" placeholder="请输入项目名称"></el-input>
        </el-col>
        <el-col :span="6">
          <el-input class="cl-input" v-model="searchForm.address" placeholder="请输入项目地址"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="medium" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="cl-table-main" v-loading="loading">
      <div class="main-top">
        <div>项目信息</div>
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="openAddCodeModel">添加</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ textAlign: 'center' }"
        class="table-wrap"
        max-height="580"
      >
        <el-table-column
          prop="title"
          label="项目名称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="views"
          label="观看次数"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="likes"
          label="点赞次数"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="isGame"
          label="是否是游戏"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="项目地址"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="handleEdit(scope.row)">修改</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <Pagination 
          :page="page" 
          :size="size" 
          :total="total" 
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>

    <Dialog 
      :isShow="addCodeModel" 
      title="添加项目" 
    >
      <addCode @closeAddCodeModel="closeAddCodeModel" />
    </Dialog>

    <Dialog 
      :isShow="editCodeModel" 
      title="修改项目" 
      :key="key"
    >
      <editCode @closeEditCodeModel="closeEditCodeModel" :editInfo="editInfo" />
    </Dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import Dialog from '@/components/Dialog';
import addCode from './components/addCodeModel.vue';
import editCode from './components/editCodeModel.vue';
import { queryProject, delProject } from '@/api/project';

export default {
  data(){
    return {
      tableData: [],

      loading: true,

      searchForm: {
        title: '',     //项目名称
        address: '',   //项目地址
      },

      page: 1,
      size: 10,
      total: 0,
      addCodeModel: false,
      editCodeModel: false,
      editInfo: {},
      key: ''
    }
  },

  methods: {
    /**
     * 处理数据编辑，修改
     */
    handleEdit(data){
      this.editInfo = data;
      this.editCodeModel = true;
    },

    /**
     * 查询
     */
    handleSearch(){
      this.queryProject(this.searchForm);
    },

    /**
     * 处理数据删除
     */
    handleDelete(data){
      const { id } = data;
      this.$utils.confirm('您确定要删除此项目信息吗?').then(async () => {
        const res = await delProject(id);
        if(res.code == 200){
          this.$showMessage({
            type: 'success',
            message: '删除成功!'
          });
          this.queryProject();
        }
      }).catch(err => {
        //点击了取消
        this.$showMessage({
          type: 'info',
          message: '已取消删除'
        }); 
      })
    },

    /**
     * size发生改变时触发
     */
    handleSizeChange(val){
      this.size = val;
      this.queryProject();
    },

    /**
     * page发生变化时触发
     */
    handleCurrentChange(val){
      this.page = val;
      this.queryProject();
    },

    /**
     * 打开添加项目信息的模态框
     */
    openAddCodeModel(){
      this.addCodeModel = true;
    },

    /**
     * 关闭添加项目的模态框
     */
    closeAddCodeModel(flag, isRefresh){
      this.addCodeModel = flag;
      if(isRefresh == 'refresh'){
        this.queryProject();
      }
    },

    /**
     * 关闭修改项目的模态框
     */
    closeEditCodeModel(flag, isRefresh){
      this.editCodeModel = flag;
      this.key = new Date().getTime();
      if(isRefresh == 'refresh'){
        this.queryProject();
      }
    },

    /**
     * 分页查询项目信息
     */
    async queryProject(){
      this.loading = true;
      try{
        const { page, size } = this;
        const res = await queryProject(page, size);
        this.loading = false;
        if(res.code == 200){
          const { count, rows } = res.data;
          this.total = count;
          this.tableData = rows.map(item => {
            return {
              ...item,
              isGame: item.isGame? '是' : '不是'
            }
          });
        }
      }catch(err){
        console.log(err);
      }
    }
  },

  created() {
    this.queryProject();
  },

  components: {
    Pagination,
    Dialog,
    addCode,
    editCode
  }
}
</script>

<style lang="less" scoped>

</style>